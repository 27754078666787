<template>
  <div class="wrapper">
    <div class="heading">
      <img src="@/assets/img/logo.png" alt="" /> <span>LOGGER</span>
    </div>
    <div class="logger mt-4">
      <p class="mb-4">Восстановление пароля</p>
      <form class="logger-form">
        <tirscript3-input
          class="email"
          type="email"
          placeholder="Введите почту"
        />
        <tirscript3-button class="mt-3">Отправить</tirscript3-button>
      </form>
    </div>
  </div>
</template>

<script>
import { Vue, Options } from "vue-property-decorator";
@Options({
  name: "password-recovery"
})

export default class PasswordRecovery extends Vue {}
</script>


<style lang="less">
.form-control {
    
}
.tirscript-button {
  align-self: flex-end;
  padding: 10px 30px !important;
  background: rgba(99, 1, 87, 1) !important;
  font-size: 14px;
  color: white;
  border: none !important;
}
.tirscript-button:hover {
  background: rgba(99, 1, 87, 0.5);
  transition: all 0.15s linear;
}
</style>